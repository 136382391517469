import React from "react"

const NotificationCircle = ({ style = {}, className = "" }) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
      className={className}
    >
      <circle cx="4" cy="4" r="4" fill="#FC4B67" />
    </svg>
  )
}

export default NotificationCircle
