import { navigate } from "gatsby-link"
import { useDispatch, useSelector } from "react-redux"
import { toggleModal } from "../../actions/settingsActions"

export default function usePrivateNavigate() {
  const user = useSelector(state => state?.user?.user)

  const dispatch = useDispatch()

  const openAuthModal = () => {
    dispatch(toggleModal({ open: true }))
  }

  const privateNavigate = (to = "/") => {
    if (!user?.id) {
      openAuthModal()
      return
    }
    navigate(to)
  }

  return { privateNavigate, openAuthModal }
}
