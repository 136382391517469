import React from 'react';

export const ExpertsIcon = (props) => {
  const {color} = props;
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M18 2H7C5.897 2 5 2.897 5 4V7C5 8.103 5.897 9 7 9H18C19.103 9 20 8.103 20 7V4C20 2.897 19.103 2 18 2ZM7 7V4H18L18.002 7H7Z" fill={color ? color :"#616E86"}/>
      <path d="M13 15V13C13 11.897 12.103 11 11 11H4V5C2.897 5 2 5.897 2 7V11C2 12.103 2.897 13 4 13H11V15C10.447 15 10 15.447 10 16V21C10 21.553 10.447 22 11 22H13C13.553 22 14 21.553 14 21V16C14 15.447 13.553 15 13 15Z" fill={color ? color :"#616E86"}/>
    </svg>
  )
}