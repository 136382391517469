import React from "react"
import { ImWhatsapp } from "react-icons/im"
import { FormattedMessage } from "react-intl"
import ReactWhatsapp from "react-whatsapp"

const WhatsappComponent = () => {
  return (
    <ReactWhatsapp number="00963949202538">
      <div className="whatsappContainer">
        <div className="whatsappIcon">
          <ImWhatsapp className="white px26" />
        </div>
        <div className="mx-2">
          <span className="black px26 font-bold">
            <FormattedMessage defaultMessage="let’s talk" />
          </span>
        </div>
      </div>
    </ReactWhatsapp>
  )
}

export default WhatsappComponent
