import React from "react"
import { FiArrowUp, FiUser } from "react-icons/fi"
import { AiOutlineInstagram, AiOutlineTwitter } from "react-icons/ai"
import { RiFacebookFill } from "react-icons/ri"
import { FiLinkedin, FiEdit } from "react-icons/fi"
import { Link } from "gatsby"
import { connect } from "react-redux"
import { Pin } from "../assets/images/mapPin"
import footer from "../assets/images/footer.png"
import useForm from "./hooksComponents/useForm"
import { isValidEmail, scrollTop } from "../utils"
import { postContact } from "../actions/dataActions"
import { FormattedMessage } from "react-intl"
import OutLineMail from "../assets/icons/outLineMail"
import useMediaQuery from "./hooksComponents/useMediaQuery"

const showInMapClicked = (latitude, longitude) => {
  window.open("https://maps.google.com?q=" + latitude + "," + longitude)
}
const Footer = props => {
  const { state, onChange, setState, setErrors, errors } = useForm({
    name: "",
    email: "",
    message: "",
  })
  const isMobile = useMediaQuery("(max-width: 991px)")
  function openInNewTab(url) {
    window.open(url, "_blank").focus()
  }
  const handleSubmit = e => {
    e.preventDefault()
    if (!state.name) {
      setErrors({
        ...errors,
        required: { name: true },
      })
      return
    }
    if (!state.email) {
      setErrors({
        ...errors,
        required: { email: true },
      })
      return
    }
    if (!isValidEmail(state.email)) {
      setErrors({
        ...errors,
        invalid: { email: true },
      })
      return
    }
    if (!state.message) {
      setErrors({
        ...errors,
        required: { message: true },
      })
      return
    }
    props.postContact(state).then(() => {
      setState({
        name: "",
        email: "",
        message: "",
      })
    })
  }

  return (
    <div
      className="relative gradientBg mt-4 px-6rem"
      style={{ paddingTop: "140px" }}
    >
      <img src={footer} alt={"footer"} className="absolute right-0 bottom-0" />
      <div className="row">
        <div
          className="col-lg-6 col-
        12 py-lg-5"
        >
          <div>
            <p className="greyLight32 footerFontTitles font-bold text-uppercase">
              <FormattedMessage defaultMessage="Follow Us" />
            </p>
            <div className="primary">
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.facebook.com")}
              >
                <RiFacebookFill size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.linkedin.com")}
              >
                <FiLinkedin
                  size={isMobile ? "2.5rem" : "1.5rem"}
                  style={{ fill: "#3C3F58" }}
                />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.instagram.com")}
              >
                <AiOutlineInstagram size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
              <span
                className="mx-3 pointer"
                onClick={() => openInNewTab("https://www.twitter.com")}
              >
                <AiOutlineTwitter size={isMobile ? "2.5rem" : "1.5rem"} />
              </span>
            </div>
          </div>

          <div className="d-lg-flex flex-wrap mt-lg-5 my-3 flex-lg-nowrap">
            <div className="me-lg-5">
              <p className="greyLight32 footerFontTitles font-bold text-uppercase">
                <FormattedMessage defaultMessage="More Links" />
              </p>
              <div className="primary footerFont font-bold">
                <p>
                  <Link to="/aboutUs">
                    <FormattedMessage defaultMessage="About Us" />
                  </Link>
                </p>
                <p>
                  <Link to="/properties?for=sale">
                    <FormattedMessage defaultMessage="Properties for sell" />
                  </Link>
                </p>
                <p>
                  <Link to="/properties?for=rent">
                    <FormattedMessage defaultMessage="Properties for rent" />
                  </Link>
                </p>
                <p>
                  <Link to="/app/service/create">
                    <FormattedMessage defaultMessage="List a service" />
                  </Link>
                </p>
                <p>
                  <Link to="/app/property/create">
                    <FormattedMessage defaultMessage="List an ad for free" />
                  </Link>
                </p>
                <p>
                  <Link to="/terms">
                    <FormattedMessage defaultMessage="Terms of Services" />
                  </Link>
                </p>
                <p>
                  <Link to="/policy">
                    <FormattedMessage defaultMessage="Privacy Policy" />
                  </Link>
                </p>
              </div>
            </div>

            <div className="mx-lg-5">
              <p className="greyLight32 footerFontTitles font-bold text-uppercase">
                <FormattedMessage defaultMessage="Get In Touch" />
              </p>
              <div className="d-flex mb-3">
                <Pin />
                <div className="mx-2 primary footerFont">
                  <p
                    onClick={() => showInMapClicked(33.522778, 36.281667)}
                    className="font-bold mb-1 primaryHover"
                    style={{cursor: 'pointer'}}
                  >
                    Abou Roummaneh, Damascus, Syria
                  </p>
                  <p className="mb-0">+96 9999 999 999</p>
                </div>
              </div>
              <div className="d-flex mb-3">
                <Pin />
                <div className="mx-2 primary footerFont">
                  <p
                    className="font-bold mb-1 primaryHover"
                    style={{cursor: 'pointer'}}
                    onClick={() => showInMapClicked(25.047128, 55.15552)}
                  >
                    Dubai, Jumeirah Park, Building 27
                  </p>
                  <p className="mb-0">+96 9999 999 999</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-6 col-12 py-lg-5">
          <div>
            <p
              className="greyLight32 footerFontTitles font-bold text-uppercase"
              style={{ marginBottom: 0 }}
            >
              <FormattedMessage defaultMessage="Send Feedbacks" />
            </p>
            <span className="primary font-bold px32">hello@jasmins.com</span>
            <div className="d-flex justify-content-end d-lg-none">
              <button
                className="font-bold px16 br8 white secondaryBg mb-5 button-shadow p-3 button-secondaryBorder"
                onClick={scrollTop}
              >
                <FiArrowUp size="2rem" />
              </button>
            </div>
          </div>

          <div>
            <form className="mt-lg-5 my-3" onSubmit={handleSubmit}>
              <p
                className="greyLight32 footerFontTitles font-bold text-uppercase"
                style={{ marginBottom: "15px" }}
              >
                <FormattedMessage defaultMessage="Keep In Touch" />
              </p>
              <div
                className="d-flex align-items-center flex-wrap flex-lg-nowrap margin-bottom-10"
                style={{ marginTop: "-20px" }}
              >
                <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 my-2 z-99">
                  <input
                    name="name"
                    type="text"
                    className="greyLightBg black br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="Your name"
                    value={state.name}
                    onChange={onChange}
                  />
                  <div>
                    <FiUser size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.name && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Name is required!" />
                  </span>
                )}
                <div className="signInInput greyLightBg br4 d-flex align-items-center my-lg-3 mx-lg-5 my-2 z-99">
                  <input
                    name="email"
                    type="text"
                    className="greyLightBg br4 flex-grow-1 px18"
                    autoComplete="off"
                    placeholder="E-mail"
                    value={state.email}
                    onChange={onChange}
                  />
                  <div>
                    <OutLineMail size="1.5rem" className="darkGrey mx-3" />
                  </div>
                </div>
                {errors.required.email && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Email is required!!" />
                  </span>
                )}
                {errors.invalid.email && (
                  <span className="error mx-2">
                    <FormattedMessage defaultMessage="Email is invalid!!" />
                  </span>
                )}
              </div>

              <div className="d-flex flex-wrap flex-lg-nowrap">
                <div className="signInInput greyLightBg br4 d-flex align-items-start my-2 z-99">
                  <textarea
                    name="message"
                    className="greyLightBg br4 flex-grow-1 px18"
                    placeholder="Leave your message"
                    rows="2"
                    cols="2"
                    value={state.message}
                    onChange={onChange}
                  />
                  <div>
                    <FiEdit size="1.5rem" className="darkGrey mx-3 my-3" />
                  </div>
                  {errors.required.message && (
                    <span className="error mx-2">
                      <FormattedMessage defaultMessage="Message is required!!" />
                    </span>
                  )}
                </div>

                <button
                  className="z-99 primaryBg px24 white br8 signInButton btnShadow d-flex align-items-center justify-content-center mx-lg-5 my-2 button-primaryBorder sendButton"
                  style={{ height: "80px" }}
                >
                  {false && (
                    <span
                      className="spinner-border spinner-border-sm me-3"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  <span className="font-bold">
                    <FormattedMessage defaultMessage="SEND" />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="d-flex align-items-end justify-content-center text-center mb-5">
        <div className="primary font-bold px14 py-5 mb-5 mb-lg-0">
          <FormattedMessage defaultMessage="Copyright © Simsar 2021. All Rights Reserved 2021. All Rights Reserved" />
        </div>
      </div>

      <div className="d-lg-flex justify-content-end d-none">
        <button
          className="absolute scrollBtn font-bold px16 br8 white secondaryBg p-3 button-shadow button-secondaryBorder"
          onClick={scrollTop}
        >
          <FiArrowUp size="2rem" />
        </button>
      </div>
    </div>
  )
}

export default connect(null, { postContact })(Footer)
