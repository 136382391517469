import React from "react"
import Header from "./header"
import Footer from "./footer"
import WhatsappComponent from "./whatsappComponent"
import useMediaQuery from "./hooksComponents/useMediaQuery"
import { useLocation } from "@reach/router"

const Layout = ({ children }) => {
  const isMobile = useMediaQuery("(max-width: 991px)")
  const { pathname } = useLocation()
  return (
    <div>
      {!pathname.includes("map-finder") ? <Header /> : null}
      {children}
      {!isMobile && <WhatsappComponent />}
      {!pathname.includes("contactUs") && !pathname.includes("map-finder") ? (
        <Footer />
      ) : null}
    </div>
  )
}

export default Layout
