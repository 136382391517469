import p1 from "../assets/images/propertiesImages/p1.png"
import p2 from "../assets/images/propertiesImages/p2.png"
import p3 from "../assets/images/propertiesImages/p3.png"
import p4 from "../assets/images/propertiesImages/p4.png"
import property1 from "../assets/images/propertiesImages/property1.png"
import property2 from "../assets/images/propertiesImages/property2.png"
import property3 from "../assets/images/propertiesImages/property3.png"
import property4 from "../assets/images/propertiesImages/property4.png"
import agent1 from "../assets/images/projectsImages/agent1.png"
import agent2 from "../assets/images/projectsImages/agent2.png"
import agent3 from "../assets/images/projectsImages/agent3.png"
import agent4 from "../assets/images/projectsImages/agent4.png"
import s1 from "../assets/images/servicesImages/s1.png"
import s2 from "../assets/images/servicesImages/s2.png"
import s3 from "../assets/images/servicesImages/s3.png"
import s4 from "../assets/images/servicesImages/s4.png"

export const moreItems = [
  { page: "About Us", route: "/aboutUs" },
  { page: "Contact Us", route: "/contactUs" },
  { page: "Terms & Conditions", route: "/terms" },
  { page: "Privacy Policy", route: "/policy" },
]

export const profileItems = [
  { page: "My Profile", route: "/app/profile" },
  { page: "My Ads", route: "/app/profile" },
  { page: "My Favorites" },
  { page: "My Brokers Follow List" },
  { page: "Log Out" },
]

export const propertiesImages = [
  {
    id: 1,
    image: p1,
    title: "Bagdad New Line",
    description: "Starting from 200 Million Syrian Pounds.",
  },
  {
    id: 2,
    image: p2,
    title: "Marota City Project",
    description: "Starting from 200 Million Syrian Pounds.",
  },
  {
    id: 3,
    image: p3,
    title: "Mashro Dammar",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 4,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 5,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 6,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 7,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 8,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
]

export const associationsImages = [
  {
    id: 1,
    image: p1,
    title: "Bagdad New Line",
    description: "Starting from 200 Million Syrian Pounds.",
  },
  {
    id: 2,
    image: p2,
    title: "Marota City Project",
    description: "Starting from 200 Million Syrian Pounds.",
  },
  {
    id: 3,
    image: p3,
    title: "Mashro Dammar",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 4,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 5,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 6,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 7,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
  {
    id: 8,
    image: p4,
    title: "Al-Mazzeh",
    description:
      "Starting from 200 Million Syrian Pounds. 400 Appartment and....",
  },
]

export const propertiesCards = [
  {
    id: 1,
    images: [property1],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 4,
    bathsCount: 2,
    isFavorite: true,
    price: "450,000,000",
  },
  {
    id: 2,
    images: [property2],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "200",
    bedRoomsCount: 5,
    bathsCount: 3,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 3,
    images: [property3],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "150",
    bedRoomsCount: 2,
    bathsCount: 1,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 4,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 5,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 6,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 7,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 8,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 9,
    images: [property4],
    offerType: "rent",
    title:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
]

export const myPropertiesCards = [
  {
    id: 1,
    images: [property1],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 4,
    bathsCount: 2,
    isFavorite: true,
    price: "450,000,000",
    status: "rejected",
  },
  {
    id: 2,
    images: [property2],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "200",
    bedRoomsCount: 5,
    bathsCount: 3,
    isFavorite: false,
    price: "450,000,000",
    status: "pending approval",
  },
  {
    id: 3,
    images: [property3],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "150",
    bedRoomsCount: 2,
    bathsCount: 1,
    isFavorite: false,
    price: "450,000,000",
  },
  {
    id: 4,
    images: [property4],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
    status: "rejected",
  },
  {
    id: 5,
    images: [property4],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
    status: "pending approval",
  },
  {
    id: 6,
    images: [property4],
    description:
      "GET THE HIGHEST RETURN OF INVESTMENT | POOL VIEW STUDIO ( RENTED 36,500 )e",
    size: "100",
    bedRoomsCount: 6,
    bathsCount: 4,
    isFavorite: false,
    price: "450,000,000",
  },
]

export const agentsCards = [
  {
    image: agent1,
    name: "Ahmed Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    offers: "Offers 100 properties",
    isFavorite: true,
  },
  {
    image: agent4,
    name: "Haya Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Hayahussan@gmail.com",
    offers: "Offers 117 properties",
    isFavorite: true,
  },
  {
    image: agent2,
    name: "Eid Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Eidhussan@gmail.com",
    offers: "Offers 19 properties",
    isFavorite: true,
  },
  {
    image: agent1,
    name: "Ahmed Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    offers: "Offers 90 properties",
    isFavorite: true,
  },
  {
    image: agent3,
    name: "Omar Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Omarhussan@gmail.com",
    offers: "Offers 50 properties",
    isFavorite: true,
  },
  {
    image: agent4,
    name: "Lana Hussan",
    phoneNumber: "+963 999 999 9999",
    email: "Lanahussan@gmail.com",
    offers: "Offers 40 properties",
    isFavorite: true,
  },
]

export const servicesCards = [
  {
    id: 1,
    image: s1,
    name: "Digital Security",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    type: "Electronics",
  },
  {
    id: 2,
    image: s2,
    name: "Kids Tree House",
    phoneNumber: "+963 999 999 9999",
    email: "Hayahussan@gmail.com",
    type: "Nurseries",
  },
  {
    id: 3,
    image: s3,
    name: "Hany Man",
    phoneNumber: "+963 999 999 9999",
    email: "Eidhussan@gmail.com",
    type: "Carpentry",
  },
  {
    id: 4,
    image: s4,
    name: "The gradners",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    type: "Landscaping",
  },
  {
    id: 5,
    image: s2,
    name: "The gradners",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    type: "Interior Design",
  },
  {
    id: 6,
    image: s3,
    name: "The gradners",
    phoneNumber: "+963 999 999 9999",
    email: "Ahmedhussan@gmail.com",
    type: "Landscaping",
  },
]
export const services = [
  { service: "Fix broken furniture", price: 2500 },
  { service: "Create new pieces", price: 3000 },
  { service: "Fix broken furniture", price: 2000 },
  { service: "Painting old furniture", price: 2500 },
  { service: "Create new pieces", price: 5000 },
  { service: "Painting old furniture", price: 2500 },
]

export const notifications = [
  {
    title: "New property added",
    description: "Ahmed Al Hassan added a new property",
    status: "added",
  },
  {
    title: "Your property was rejected",
    description: "Noor rejected property",
    status: "rejected",
  },
  {
    title: "Your new ad was approved",
    description: "Your new ad was approved!!",
    status: "approved",
  },
]

export const allServices = [
  { id: 0, title: "Carpentry", isSelected: false },
  { id: 1, title: "Cleaning", isSelected: false },
  { id: 2, title: "Electrical", isSelected: false },
  { id: 3, title: "Handyman", isSelected: false },
  { id: 4, title: "Home Services", isSelected: false },
  { id: 5, title: "Insurance", isSelected: false },
  { id: 6, title: "Interior Design", isSelected: false },
  { id: 7, title: "Landscaping", isSelected: false },
  { id: 8, title: "Painting", isSelected: false },
  { id: 9, title: "Nurseries", isSelected: false },
  { id: 10, title: "Packers & Movers", isSelected: false },
  { id: 11, title: "Painting", isSelected: false },
  { id: 12, title: "Nurseries", isSelected: false },
  { id: 13, title: "Packers & Movers", isSelected: false },
  { id: 14, title: "Cleaning", isSelected: false },
  // {id:15, title:"Handyman", isSelected:false},
  // {id:16, title:"Interior Design", isSelected:false},
]
