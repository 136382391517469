import React, { useState, Fragment } from "react"
import bigLogo from "../assets/images/bigLogo.svg"
import noUserIcon from "../assets/images/profile.svg"
import { FiMoreVertical } from "react-icons/fi"
import { Link, navigate } from "gatsby"
import { connect } from "react-redux"
import {
  setGeneralModal,
  setPropertyFiltersField,
  toggleLanguage,
  toggleModal,
} from "../actions/settingsActions"
import HeaderDropDownComponent from "./headerComponents/headerDropDown"
import { moreItems } from "../constants/headerItems"
import { ProfileIcon } from "../assets/images/responsiveIcons/profileIcon"
import { MapIcon } from "../assets/images/responsiveIcons/mapIcon"
import { ExpertsIcon } from "../assets/images/responsiveIcons/expertsIcon"
import { PropertiesIcon } from "../assets/images/responsiveIcons/propertiesIcon"
import isEmpty from "lodash/isEmpty"
import { FormattedMessage } from "react-intl"
import { logoutUser } from "../actions/authActions"
import ChevDown from "../assets/icons/chevDown"
import usePrivateNavigate from "./hooksComponents/usePrivateNavigate"
import { GiHamburgerMenu } from "react-icons/gi"
import { BsArrowLeftSquare } from "react-icons/bs"
import { Box, Button, Drawer, IconButton, Tooltip } from "@material-ui/core"
import NotificationIcon from "../assets/icons/notification-icon"
import NotificationCircle from "../assets/icons/notification-circle"

export const myProfilePath = "/app/my-profile"

const Header = props => {
  const { user, lang, setPropertyFiltersField } = props
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [dropDownItems, setDropDownItems] = useState([])

  const { privateNavigate, openAuthModal } = usePrivateNavigate()
  const toggleDrawer = open => {
    console.log(open)
    setDrawerOpen(open)
  }
  const profileItems = [
    {
      page: <FormattedMessage defaultMessage="My Profile" />,
      route: myProfilePath,
    },
    {
      page: <FormattedMessage defaultMessage="My Ads" />,
      route: myProfilePath,
    },
    {
      page: <FormattedMessage defaultMessage="My Favorites" />,
      route: `${myProfilePath}/favorites`,
    },
    {
      page: <FormattedMessage defaultMessage="My Brokers Follow List" />,
      route: `${myProfilePath}/followers`,
    },
    {
      page: <FormattedMessage defaultMessage="Log Out" />,
      action: () => {
        props.logoutUser()
        toggleDrawer(false)
        openAuthModal()
        setAnchorEl(null)
      },
    },
  ]

  const propertiesItems = [
    {
      page: <FormattedMessage defaultMessage="For Sale" />,
      action: () => {
        setPropertyFiltersField("offerType", "sale")
        navigate("/properties?for=sale")
        setAnchorEl(null)
        toggleDrawer(false)
      },
    },
    {
      page: <FormattedMessage defaultMessage="For Rent" />,
      action: () => {
        setPropertyFiltersField("offerType", "rent")
        navigate("/properties?for=rent")
        setAnchorEl(null)
        toggleDrawer(false)
      },
    },
    {
      page: <FormattedMessage defaultMessage="Off Plan Projects" />,
      action: () => {
        navigate("/offplan-projects")
        setAnchorEl(null)
        toggleDrawer(false)
      },
    },
    {
      page: <FormattedMessage defaultMessage="Housing Association" />,
      action: () => {
        navigate("/housing-association")
        setAnchorEl(null)
        toggleDrawer(false)
      },
    },
  ]

  const openPopover = (e, itemName) => {
    setAnchorEl(e.currentTarget)
    if (itemName === "more") {
      setDropDownItems(moreItems)
    } else if (itemName === "profile") {
      setDropDownItems(profileItems)
    } else {
      setDropDownItems(propertiesItems)
    }
  }

  const switchLanguage = () => props.toggleLanguage(lang === "en" ? "ar" : "en")

  const openListPropertyForm = () => {
    privateNavigate("/app/property/create")
  }

  const openListServiceForm = () => {
    privateNavigate("/app/service/create")
  }

  return (
    <Fragment>
      <div
        style={{ height: 75 }}
        className="top-0 left-0 d-flex align-items-center justify-content-between px-2 whiteBg sticky z-99 "
      >
        <div className="d-flex align-items-center">
          <div className="headerMobile">
            <button
              style={{
                height: "45px",
                width: "45px",
                fontSize: "17px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={() => toggleDrawer(!drawerOpen)}
              className="mx-1 primary font-bold px12 border2-primary br8 button-primaryBg mt-1"
            >
              <GiHamburgerMenu />
            </button>
          </div>
          <Link className="outline-none mr-auto px-8" to="/">
            <img
              style={{ width: "115px", height: "32px" }}
              src={bigLogo}
              alt="logo"
            />
          </Link>
        </div>
        <div
          className="flex-grow-1 px-4 h-100 header"
          style={{ fontSize: "17px" }}
        >
          <button
            className="d-flex align-items-center darkGrey primaryHover mt-2"
            onClick={e => openPopover(e, "properties")}
          >
            <FormattedMessage defaultMessage="Browse Properties" />
            <ChevDown className={"mx-1"} />
          </button>
          <Link
            className="d-flex align-items-center mx-lg-3 mt-2 darkGrey"
            to="/app/map-finder"
          >
            <FormattedMessage defaultMessage="Map Finder" />
          </Link>
          <Link
            className="d-flex align-items-center mx-lg-3 mt-2 darkGrey"
            to="/app/all-services"
          >
            <FormattedMessage defaultMessage="Community Experts" />
          </Link>
          <button
            className="d-flex align-items-center darkGrey mt-2 primaryHover"
            onClick={e => openPopover(e, "more")}
          >
            <FormattedMessage defaultMessage="More" />
            <ChevDown className={"mx-1"} />
          </button>
        </div>
        <div className="d-flex align-items-center">
          <button
            disabled
            className="mx-1 px-8 py-10 d-none d-lg-block darkGrey primaryHover greyLightBg br8 mt-1"
            style={{ width: "140px", height: "45px", fontSize: "17px" }}
            onClick={""}
          >
            <FormattedMessage defaultMessage="Currency SP" />
          </button>
          <div className="d-none d-lg-flex pointer mt-1">
            <button
              style={{ width: "50px", height: "45px", fontSize: "17px" }}
              className="mx-1 px-8 py-10 darkGrey primaryHover greyLightBg br8 text-capitalize"
              onClick={switchLanguage}
            >
              {lang || "En"}
            </button>
          </div>

          {user && !isEmpty(user) ? (
            <div className="d-none d-lg-flex justify-content-center pointer mt-1 relative">
              <Link
                style={{ width: "45px", height: "45px", fontSize: "17px" }}
                className="mx-1 px-8 py-10 darkGrey d-flex justify-content-center relative align-items-center primaryHover greyLightBg br8 text-capitalize"
                to="/app/my-profile/notifications"
              >
                <NotificationIcon style={{ width: 22, height: 22 }} />
                <NotificationCircle style={{position: 'absolute', top: 7, right: 7}} />
              </Link>
            </div>
          ) : null}

          <div
            className={`d-lg-flex pointer ${
              user && !isEmpty(user) ? "mt-1" : ""
            }`}
          >
            {user && !isEmpty(user) ? (
              <div
                className="mx-1 darkGrey primaryHover greyLightBg br8"
                onClick={e => openPopover(e, "profile")}
              >
                {user?.avatar?.thumbnail ? (
                  <img
                    className="br8"
                    style={{
                      objectFit: "cover",
                      width: "50px",
                      height: "45px",
                    }}
                    src={user?.avatar?.thumbnail || noUserIcon}
                    alt="User Avatar"
                  />
                ) : (
                  <img
                    className="br8"
                    style={{
                      objectFit: "cover",
                      width: "50px",
                      height: "45px",
                    }}
                    src={noUserIcon}
                    alt="User Avatar"
                  />
                  // <ProfileIcon className={"svgPrimaryHover"} />
                )}
              </div>
            ) : (
              <button
                style={{ height: 45 }}
                key={"userAccountBtn"}
                className="mx-1 px-8 py-10 darkGrey primaryHover greyLightBg br8 mt-1"
                onClick={openAuthModal}
              >
                <FormattedMessage defaultMessage="My Account" />
              </button>
            )}
          </div>

          <div className="d-none d-md-flex">
            <Tooltip
              enterDelay={1000}
              title={
                <FormattedMessage
                  defaultMessage="List an ad for your services on our website, if you fix AIR
            conditioners, electricity or you do plumbing, you can advertise your
            business on our website"
                />
              }
              classes={{
                tooltip: "br16 px14 primaryBg white w-20rem padding-20 z-5",
              }}
            >
              <button
                style={{
                  height: "45px",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
                className="mx-1 px-16 py-10 primary font-bold px12 border2-primary br8 button-primaryBg mt-1"
                data-tip={"list-service"}
                data-for="list-service"
                onClick={openListServiceForm}
              >
                <FormattedMessage defaultMessage="List a service" />
              </button>
            </Tooltip>

            <Tooltip
              enterDelay={1000}
              title={
                <FormattedMessage
                  defaultMessage="If you are an house owner or a broker, you can list your properties
            ads for free with us"
                />
              }
              classes={{
                tooltip: "br16 px14 secondaryBg white w-20rem padding-20 z-5",
              }}
            >
              <button
                style={{ height: "45px", fontSize: "16px" }}
                className="mx-1 px-16 py-12 white secondaryBg font-bold px12 br8 button-secondaryBorder mt-1"
                data-tip="list-property"
                data-for={"list-property"}
                onClick={openListPropertyForm}
              >
                <FormattedMessage defaultMessage="List property for free" />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>

      <div className="d-flex d-lg-none fixed bottom-0 left-0 w-100 z-100 h-10vh align-items-center justify-content-between whiteBg bottomMenuShadow px-2 px14">
        <button
          className={`darkGrey px-1 primaryHover cursor-default mx-1 d-flex flex-column justify-content-center align-items-center`}
          onClick={e => openPopover(e, "properties")}
        >
          <span className="my-1">
            <PropertiesIcon />
          </span>
          <div className="my-1">
            <FormattedMessage defaultMessage="Properties" />
          </div>
        </button>
        <button
          className="darkGrey px-1 primaryHover cursor-default mx-1 d-flex flex-column justify-content-center align-items-center"
          onClick={() => navigate("/app/map-finder")}
        >
          <span className="my-1">
            <MapIcon />
          </span>
          <div className="my-1">
            <FormattedMessage defaultMessage="Map Finder" />
          </div>
        </button>
        <button
          className="darkGrey px-1 primaryHover cursor-default mx-1 d-flex flex-column justify-content-center align-items-center"
          onClick={() => navigate("/app/all-services")}
        >
          <span className="my-1">
            <ExpertsIcon />
          </span>
          <div className="my-1">
            <FormattedMessage defaultMessage="Experts" />
          </div>
        </button>
        <button
          className={`darkGrey px-1 primaryHover cursor-default mx-1 d-flex flex-column justify-content-center align-items-center`}
          onClick={e => openPopover(e, "more")}
        >
          <FiMoreVertical className={"my-1"} size="1.5rem" />
          <div className="my-1">
            <FormattedMessage defaultMessage="More" />
          </div>
        </button>
        <button
          className="darkGrey px-1 primaryHover cursor-default mx-1 d-flex flex-column justify-content-center align-items-center"
          onClick={e =>
            user && !isEmpty(user) ? openPopover(e, "profile") : openAuthModal()
          }
        >
          <span className="my-1">
            <ProfileIcon />
          </span>
          <div className="my-1">
            <FormattedMessage defaultMessage="My Account" />
          </div>
        </button>
      </div>
      <Drawer
        anchor={"left"}
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
      >
        <div className="d-flex w-100 align-items-center justify-content-between shadow-sm p-4">
          <Link className="outline-none mr-auto px-0" to="/">
            <img
              style={{ width: "115px", height: "32px" }}
              src={bigLogo}
              alt="logo"
            />
          </Link>
          <IconButton
            onClick={() => toggleDrawer(false)}
            style={{ padding: 2 }}
            className="primary"
          >
            <BsArrowLeftSquare style={{ height: "35px", width: "35px" }} />
          </IconButton>
        </div>
        <div
          className="p-4 d-flex flex-column align-items-start"
          style={{ fontSize: "17px", minWidth: 280 }}
        >
          <button
            disabled
            className="mx-1 px-8 py-10 d-block d-lg-none darkGrey primaryHover greyLightBg br8 mt-4"
            style={{ width: "250px", height: "45px", fontSize: "17px" }}
            onClick={""}
          >
            <FormattedMessage defaultMessage="Currency SP" />
          </button>
          <div className="d-flex d-lg-none pointer mt-3">
            <button
              style={{ width: "250px", height: "45px", fontSize: "17px" }}
              className="mx-1 px-8 py-10 darkGrey primaryHover greyLightBg br8 text-capitalize"
              onClick={switchLanguage}
            >
              {lang || "En"}
            </button>
          </div>
          <button
            className="d-none d-lg-flex align-items-center darkGrey primaryHover mt-2"
            onClick={e => openPopover(e, "properties")}
          >
            <FormattedMessage defaultMessage="Browse Properties" />
            <ChevDown className={"mx-1"} />
          </button>
          <Link
            className="d-none d-lg-flex align-items-center mt-3 mx-2 darkGrey"
            to="/app/map-finder"
          >
            <FormattedMessage defaultMessage="Map Finder" />
          </Link>
          <Link
            className="d-none d-lg-flex align-items-center mt-3 mx-2 darkGrey"
            to="/app/all-services"
          >
            <FormattedMessage defaultMessage="Community Experts" />
          </Link>
          <button
            className="d-none d-lg-flex align-items-center darkGrey mt-3 primaryHover"
            onClick={e => openPopover(e, "more")}
          >
            <FormattedMessage defaultMessage="More" />
            <ChevDown className={"mx-1"} />
          </button>
          <div className="d-flex d-md-none flex-column mt-3">
            <Tooltip
              enterDelay={1000}
              title={
                <FormattedMessage
                  defaultMessage="List an ad for your services on our website, if you fix AIR
            conditioners, electricity or you do plumbing, you can advertise your
            business on our website"
                />
              }
              classes={{
                tooltip: "br16 px14 primaryBg white w-20rem padding-20 z-5",
              }}
            >
              <button
                style={{
                  height: "45px",
                  width: "250px",
                  fontSize: "16px",
                }}
                className="mx-1 px-16 py-10 primary font-bold px12 border2-primary br8 button-primaryBg mt-1"
                data-tip={"list-service"}
                data-for="list-service"
                onClick={openListServiceForm}
              >
                <FormattedMessage defaultMessage="List a service" />
              </button>
            </Tooltip>

            <Tooltip
              enterDelay={1000}
              title={
                <FormattedMessage
                  defaultMessage="If you are an house owner or a broker, you can list your properties
            ads for free with us"
                />
              }
              classes={{
                tooltip: "br16 px14 secondaryBg white w-20rem padding-20 z-5",
              }}
            >
              <button
                style={{ height: "45px", width: "250px", fontSize: "16px" }}
                className="mx-1 px-16 py-12 white secondaryBg font-bold px12 br8 button-secondaryBorder mt-3"
                data-tip="list-property"
                data-for={"list-property"}
                onClick={openListPropertyForm}
              >
                <FormattedMessage defaultMessage="List property for free" />
              </button>
            </Tooltip>
          </div>
        </div>
      </Drawer>
      <HeaderDropDownComponent
        setDrawerOpen={setDrawerOpen}
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        items={dropDownItems}
      />
    </Fragment>
  )
}

const mapStateToProps = ({
  settings: { lang },
  user: { user },
  shared: { loading },
}) => ({ user, loading, lang })
export default connect(mapStateToProps, {
  logoutUser,
  toggleModal,
  toggleLanguage,
  setGeneralModal,
  setPropertyFiltersField,
})(Header)
