import React, { useRef, Fragment } from "react"
import { Popover } from "@material-ui/core"
import { connect } from "react-redux"
import { navigate } from "gatsby"

const HeaderDropDownComponent = ({
  setDrawerOpen,
  anchorEl,
  setAnchorEl,
  items = [],
}) => {
  const parentPopperRef = useRef(null)

  const closePopover = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  return (
    <Fragment>
      <Popover
        classes={{ paper: "popper-dropDown br8" }}
        id={open ? "simple-popover" : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={closePopover}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div className="px-3 py-2" ref={parentPopperRef}>
          {items.map((item, i) => (
            <button
              key={i}
              className="darkGrey px18 primaryHover py-2 d-flex align-items-center justify-content-between w-100"
              onClick={
                item.action && typeof item.action === "function"
                  ? item.action
                  : () => {
                      setDrawerOpen(false)
                      navigate(item.route)
                    }
              }
            >
              {item.page}
            </button>
          ))}
        </div>
      </Popover>
      <div className={!!anchorEl ? "popover-layout" : ""}> </div>
    </Fragment>
  )
}

export default HeaderDropDownComponent
